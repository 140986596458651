import { IGridColumns, ILocales, IMenuItems } from 'src/app/core/models/app.models';
import { ENavBar } from 'src/app/core/enums/nav-bar.enum';
import { CountryCode } from 'libphonenumber-js';
import { ITabs } from 'src/app/core/models/news-and-banners.model';
import { EUserRole } from 'src/app/core/enums/user-role.enum';
import { HtmlTemplateEnum } from 'src/app/core/enums/grid.enum';

export const rolesWithoutDealer: EUserRole[] = [EUserRole.ADMIN, EUserRole.USER];
export const rolesWithoutUser: EUserRole[] = [EUserRole.ADMIN, EUserRole.DEALER];
export const rolesWithoutUserAndDealer: EUserRole[] = [EUserRole.ADMIN, EUserRole.MANAGER];
export const allRoles: EUserRole[] = [EUserRole.ADMIN, EUserRole.DEALER, EUserRole.USER, EUserRole.MANAGER, EUserRole.PARTNER];

export const menuItems: IMenuItems[] = [
    {
        text: $localize`Dashboard`,
        url: ENavBar.DASHBOARD,
        img: './assets/images/nav-blue-icons/dashboard-blue.svg',
        whiteImg: './assets/images/nav-white-icons/dashboard-white.svg',
        roles: rolesWithoutUserAndDealer
    },
    {
        text: $localize`News & Banners`,
        url: ENavBar.NEWS_AND_BANNERS,
        img: './assets/images/nav-blue-icons/news-banners-blue.svg',
        whiteImg: './assets/images/nav-white-icons/news-banners-white.svg',
        roles: rolesWithoutUserAndDealer
    },
    {
        text: $localize`Users`,
        url: ENavBar.USERS,
        img: './assets/images/nav-blue-icons/user-blue.svg',
        whiteImg: './assets/images/nav-white-icons/user-white.svg',
        roles: rolesWithoutUser
    },
    {
        text: $localize`Dealers`,
        url: ENavBar.DEALERS,
        img: './assets/images/nav-blue-icons/dealers-blue.svg',
        whiteImg: './assets/images/nav-white-icons/dealers-white.svg',
        roles: rolesWithoutUserAndDealer
    },
    {
        text: $localize`POS`,
        url: ENavBar.POS,
        img: './assets/images/nav-blue-icons/pos-blue.svg',
        whiteImg: './assets/images/nav-white-icons/pos-white.svg',
        roles: rolesWithoutUser
    },
    {
        text: $localize`Providers`,
        url: ENavBar.PROVIDERS,
        img: './assets/images/nav-blue-icons/provider-blue.svg',
        whiteImg: './assets/images/nav-white-icons/provider-white.svg',
        roles: rolesWithoutUserAndDealer
    },
    {
        text: $localize`Products`,
        url: ENavBar.PRODUCTS,
        img: './assets/images/nav-blue-icons/products-blue.svg',
        whiteImg: './assets/images/nav-white-icons/products-white.svg',
        roles: rolesWithoutUserAndDealer
    },
    {
        text: $localize`Top-Up`,
        url: ENavBar.TOP_UP,
        img: './assets/images/nav-blue-icons/top-up-blue.svg',
        whiteImg: './assets/images/nav-white-icons/top-up-white.svg',
        roles: rolesWithoutDealer
    },
    {
        text: $localize`Future Top-Up`,
        url: ENavBar.FUTURE_TOP_UP,
        img: './assets/images/nav-blue-icons/top-up-future-blue.svg',
        whiteImg: './assets/images/nav-white-icons/top-up-future-white.svg',
        roles: rolesWithoutDealer
    },
    {
        text: $localize`Recurring Top-Up`,
        url: ENavBar.RECURRING_TOP_UP,
        img: './assets/images/nav-blue-icons/top-up-recurring-blue.svg',
        whiteImg: './assets/images/nav-white-icons/top-up-recurring-white.svg',
        roles: rolesWithoutDealer
    },
    {
        text: $localize`Bulk Top-Up`,
        url: ENavBar.BULK_TOP_UP,
        img: './assets/images/nav-blue-icons/top-up-bulk-blue.svg',
        whiteImg: './assets/images/nav-white-icons/top-up-bulk-white.svg',
        roles: rolesWithoutDealer
    },
    {
        text: $localize`Cancel Transaction`,
        url: ENavBar.CANCEL_TRANSACTION,
        img: './assets/images/nav-blue-icons/cancel-transaction-blue.svg',
        whiteImg: './assets/images/nav-white-icons/cancel-transaction-white.svg',
        roles: allRoles
    },
    {
        text: $localize`Reports`,
        url: ENavBar.REPORTS,
        img: './assets/images/nav-blue-icons/reports-blue.svg',
        whiteImg: './assets/images/nav-white-icons/reports-white.svg',
        roles: allRoles
    },
    {
        text: $localize`Financial Transaction`,
        url: ENavBar.FINANCIAL_TRANSACTION,
        img: './assets/images/nav-blue-icons/financial-transaction-blue.svg',
        whiteImg: './assets/images/nav-white-icons/financial-transaction-white.svg',
        roles: rolesWithoutUser
    }
];

export const localesList: ILocales[] = [
    { shortCode: 'En', code: 'en', label: 'English' },
    { shortCode: 'Ar', code: 'ar', label: 'عربيه' },
    { shortCode: 'He', code: 'he', label: 'עברית' }
];

export const supportNumber: string = '03-567-1414';

const callSupport: string = $localize`Call support`;

export const supportContact: string =
    `
        <div class="support">
           <a href='tel:${supportNumber}' class="color-primary remove-link-styles">${callSupport}</a> &nbsp;
           <a href="tel:${supportNumber}" class="color-primary remove-link-styles" dir="ltr">${supportNumber}</a>
        </div>
    `;

export const phoneNumberValidatorByCountryCode: CountryCode = 'IL';

export const LanguageTabs: ITabs[] = [
    {
        id: 1,
        text: 'English',
        code: 'en'
    },
    {
        id: 2,
        text: 'עברית',
        code: 'he'
    },
    {
        id: 3,
        text: 'عربيه',
        code: 'ar'
    }
];

export const generatedPasswordLength: number = 9;

export const reasonOfCancellation: { [p: string]: string | number }[] = [
    {
        text: $localize`Wrong phone Number`,
        value: 'WrongPhoneNumber',
        id: 1
    },
    {
        text: $localize`Wrong Product`,
        value: 'WrongProduct',
        id: 2
    },
    {
        text: $localize`Not paid`,
        value: 'NotPaid',
        id: 3
    },
    {
        text: $localize`Buyer regretted`,
        value: 'BuyerRegretted',
        id: 4
    },
    {
        text: $localize`Other`,
        value: 'Other',
        id: 5
    }
];

export const productCategory: { text: string, value: string, id: number }[] = [
    {
        text: $localize`Main Account Card`,
        value: 'כרטיס חשבון ראשי',
        id: 1
    },
    {
        text: $localize`Bonus Card`,
        value: 'כרטיס בונוס',
        id: 2
    },
    {
        text: $localize`Unlimited Card`,
        value: 'כרטיס ללא הגבלה',
        id: 3
    },
    {
        text: $localize`Surfing Card`,
        value: 'כרטיס גלישה',
        id: 4
    },
    {
        text: $localize`Content Card`,
        value: 'כרטיס תוכן',
        id: 5
    },
    {
        text: $localize`Kosher Card`,
        value: 'כרטיס כשר',
        id: 6
    }
];

export const currencyCode: number = 376;

export const mandatoryFields: string = $localize`*Mandatory fields`;

export const billingTypes: { [p: string]: string | number }[] = [
    {
        id: 1,
        name: $localize`Balance at end of day`,
        type: 'Amount'
    },
    {
        id: 2,
        name: $localize`Day of week (repeating every week)`,
        type: 'DayOfWeek'
    },
    {
        id: 3,
        name: $localize`Days of Month`,
        type: 'DayOfMonth'
    }
];

export const dayOfWeek: { shortValue: string, value: string, id: number }[] = [
    {
        shortValue: 'Sun',
        value: 'Sunday',
        id: 1
    },
    {
        shortValue: 'Mon',
        value: 'Monday',
        id: 2
    },
    {
        shortValue: 'Tue',
        value: 'Tuesday',
        id: 3
    },
    {
        shortValue: 'Wed',
        value: 'Wednesday',
        id: 4
    },
    {
        shortValue: 'Thu',
        value: 'Thursday',
        id: 5
    },
    {
        shortValue: 'Fri',
        value: 'Friday',
        id: 6
    },
    {
        shortValue: 'Sat',
        value: 'Saturday',
        id: 7
    },
];

export const reportGridColumns: { [p: number]: { headersForTotal: string[], columns: IGridColumns[] } } = {
    10: {
        headersForTotal: ['retailPrice', 'wholesalePrice', 'profit'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'type',
                headerText: $localize`Type`,
                width: 80,
                headerTextAlign: 'Center'
            },
            {
                field: 'status',
                headerText: $localize`Status`,
                width: 100,
                headerTextAlign: 'Center',
                textAlign: 'Center'
            },
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 120,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'pointOfSaleCode',
                headerText: $localize`Code(POS)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'pointOfSaleName',
                headerText: $localize`POS Name`,
                width: 120,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'buyerPhoneNumber',
                headerText: $localize`Phone`,
                width: 120,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`Provider Ref.No`,
                width: 180,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'productCode',
                headerText: $localize`Code(Product)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'retailPrice',
                headerText: $localize`Retail`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 80,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'wholesalePrice',
                headerText: $localize`Wholesale`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'profit',
                headerText: $localize`Profit`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'balance',
                headerText: $localize`Balance`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
    20: {
        headersForTotal: ['topupsCount', 'totalRetail', 'totalWholesale', 'totalProfit', 'cancelsCount', 'totalCancelRefund'],
        columns: [
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'topupsCount',
                headerText: $localize`Count`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalProfit',
                headerText: $localize`Profit`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'cancelsCount',
                headerText: $localize`Cancel Count`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'totalCancelRefund',
                headerText: $localize`Refund`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
    30: {
        headersForTotal: ['amount'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'senderCode',
                headerText: $localize`Code`,
                width: 80,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'senderName',
                headerText: $localize`From`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'beneficiaryCode',
                headerText: $localize`Code`,
                width: 80,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'beneficiaryName',
                headerText: $localize`To`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'amount',
                headerText: $localize`Amount`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'description',
                headerText: $localize`Description`,
                width: 150,
                headerTextAlign: 'Center'
            },
            {
                field: 'accountingDocumentsURL',
                headerText: $localize`Document`,
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Doc,
                headerTextAlign: 'Center'
            }
        ]
    },
    31: {
        headersForTotal: ['amount'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'senderCode',
                headerText: $localize`Code`,
                width: 80,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'senderName',
                headerText: $localize`From`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'beneficiaryCode',
                headerText: $localize`Code`,
                width: 80,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'beneficiaryName',
                headerText: $localize`To`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'amount',
                headerText: $localize`Amount`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'description',
                headerText: $localize`Description`,
                width: 150,
                headerTextAlign: 'Center'
            },
            {
                field: 'accountingDocumentsURL',
                headerText: $localize`Document`,
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Doc,
                headerTextAlign: 'Center'
            }
        ]
    },
    40: {
        headersForTotal: ['refundAmount', 'canceledRetailPrice'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                type: 'datetime',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'status',
                headerText: $localize`Status`,
                width: 150,
                headerTextAlign: 'Center'
            },
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'productCode',
                headerText: $localize`Product Code`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'refundAmount',
                headerText: $localize`Refund`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'buyerPhoneNumber',
                headerText: $localize`Phone`,
                width: 120,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`Provider Ref.No`,
                width: 180,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'cancelationReason',
                headerText: $localize`Reason`,
                width: 150,
                headerTextAlign: 'Center'
            },
            {
                field: 'canceledDateTime',
                headerText: $localize`Original Time`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'canceledRetailPrice',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'canceledProviderReferenceNo',
                headerText: $localize`Original Provider Ref.No`,
                width: 250,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            }
        ]
    },
    50: {
        headersForTotal: ['retailPrice'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Top-Up Date`,
                filter: true,
                width: 150,
                headerTextAlign: 'Center'
            },
            {
                field: 'buyerPhoneNumber',
                headerText: $localize`Phone`,
                filter: true,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'retailPrice',
                headerText: $localize`Price`,
                filter: true,
                width: 80,
                HtmlTemplateName: HtmlTemplateEnum.Amount,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                type: 'number'
            },
            {
                field: 'status',
                headerText: $localize`Status`,
                filter: true,
                width: 80,
                headerTextAlign: 'Center',
                textAlign: 'Center'
            }
        ]
    },
    110: {
        headersForTotal: ['retailPrice', 'wholesalePrice', 'profit'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                headerTextAlign: 'Center'
            },
            {
                field: 'type',
                headerText: $localize`Type`,
                width: 80,
                headerTextAlign: 'Center'
            },
            {
                field: 'status',
                headerText: $localize`Status`,
                width: 100,
                headerTextAlign: 'Center',
                textAlign: 'Center'
            },
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 120,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'pointOfSaleCode',
                headerText: $localize`Code(POS)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'pointOfSaleName',
                headerText: $localize`POS Name`,
                width: 120,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'buyerPhoneNumber',
                headerText: $localize`Phone`,
                width: 120,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`Provider Ref.No`,
                width: 180,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 150,
                filter: true,
                headerTextAlign: 'Center'
            },
            {
                field: 'productCode',
                headerText: $localize`Code(Product)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'retailPrice',
                headerText: $localize`Retail`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 80,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'wholesalePrice',
                headerText: $localize`Wholesale`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'profit',
                headerText: $localize`Profit`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
    120: {
        headersForTotal: ['topupsCount', 'totalRetail', 'totalWholesale', 'totalProfit', 'cancelsCount', 'totalCancelRefund'],
        columns: [
            { field: 'providerName', headerText: $localize`Provider`, width: 150, filter: true },
            { field: 'topupsCount', headerText: $localize`Count`, textAlign: 'Right', headerTextAlign: 'Center', width: 150 },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalProfit',
                headerText: $localize`Profit`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'cancelsCount',
                headerText: $localize`Cancel Count`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'totalCancelRefund',
                headerText: $localize`Refund`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalCancelRefund',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalCAncelWholesale',
                headerText: $localize`Wholesale`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalCancelProfit',
                headerText: $localize`Refund`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
    130: {
        headersForTotal: ['amount'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                headerTextAlign: 'Center',
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            },
            {
                field: 'type',
                headerTextAlign: 'Center',
                headerText: $localize`Type`,
                width: 80,
                filter: true
            },
            { field: 'senderCode', headerText: $localize`Code`, width: 80, filter: true, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'senderName', headerText: $localize`From`, width: 200, filter: true, headerTextAlign: 'Center' },
            { field: 'beneficiaryCode', headerText: $localize`Code`, width: 80, filter: true, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'beneficiaryName', headerText: $localize`To`, width: 200, filter: true, headerTextAlign: 'Center' },
            {
                field: 'amount',
                headerText: $localize`Amount`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`No.`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            { field: 'description', headerText: $localize`Description`, width: 150, headerTextAlign: 'Center' },
            {
                field: 'accountingDocumentsURL',
                headerText: $localize`Document`,
                headerTextAlign: 'Center',
                width: 100,
                HtmlTemplateName: HtmlTemplateEnum.Doc
            }
        ]
    },
    131: {
        headersForTotal: ['amount'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                headerTextAlign: 'Center',
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            },
            {
                field: 'type',
                headerTextAlign: 'Center',
                headerText: $localize`Type`,
                width: 80,
                filter: true
            },
            {
                field: 'senderCode',
                headerText: $localize`Code`,
                width: 80,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            { field: 'senderName', headerText: $localize`From`, width: 200, filter: true, headerTextAlign: 'Center' },
            { field: 'beneficiaryCode', headerText: $localize`Code`, width: 80, filter: true, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'beneficiaryName', headerText: $localize`To`, width: 200, filter: true, headerTextAlign: 'Center' },
            {
                field: 'amount',
                headerText: $localize`Amount`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`No.`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            { field: 'description', headerText: $localize`Description`, width: 150, headerTextAlign: 'Center' },
            {
                field: 'accountingDocumentsURL',
                headerText: $localize`Document`,
                headerTextAlign: 'Center',
                width: 100,
                HtmlTemplateName: HtmlTemplateEnum.Doc
            }
        ]
    },
    140: {
        headersForTotal: ['refundAmount', 'canceledRetailPrice'],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            }, {
                field: 'posCode',
                headerText: $localize`Code(POS)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'posName',
                headerText: $localize`POS`,
                width: 140,
                filter: true
            },
            {
                field: 'status',
                headerText: $localize`Status`,
                width: 100,
                textAlign: 'Center',
                headerTextAlign: 'Center'
            },
            {
                field: 'partial',
                headerText: $localize`Partial`,
                width: 100,
                textAlign: 'Center',
                headerTextAlign: 'Center'
            },

            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 150,
                filter: true
            },
            {
                field: 'buyerPhoneNumber',
                headerText: $localize`Phone`,
                width: 150, filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'productCode',
                headerText: $localize`Code(Product)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 120,
                filter: true
            },
            {
                field: 'referenceNo',
                headerText: $localize`Ref.No`,
                width: 180,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'canceledRetailPrice',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'refundAmount',
                headerText: $localize`Refund`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'wholesaleRefundAmount',
                headerText: $localize`Wholesale`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'clientRefundAmount',
                headerText: $localize`POS Refund`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'providerReferenceNo',
                headerText: $localize`Provider Ref.No`,
                width: 180, textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'canceledDateTime',
                headerText: $localize`Original Time`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            },
            {
                field: 'canceledProviderReferenceNo',
                headerText: $localize`Original Provider Ref.No`,
                width: 250,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'cancelationReason',
                headerText: $localize`Reason`,
                width: 150
            }
        ]
    },
    200: {
        headersForTotal: [],
        columns: [
            {
                field: 'time',
                headerText: $localize`Time`,
                headerTextAlign: 'Center',
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            },
            {
                field: 'type',
                headerText: $localize`Type`,
                width: 80
            },
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 120,
                filter: true
            },
            {
                field: 'productCode',
                headerText: $localize`Code(Product)`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 120,
                filter: true
            },
            {
                field: 'phoneNumber',
                headerText: $localize`Phone`,
                width: 120,
                filter: true,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },
            {
                field: 'pointOfSaleCode',
                headerText: $localize`Code(POS)`,
                width: 60,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'pointOfSaleName',
                headerText: $localize`POS`,
                width: 120,
                filter: true
            },
            {
                field: 'errorDescription',
                headerText: $localize`Error`,
                width: 260
            },
            {
                field: 'transactionId',
                headerText: $localize`Reference`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            }
        ]
    },
    210: {
        headersForTotal: ['totalRetail', 'totalWholesale', 'totalProfit'],
        columns: [
            {
                field: 'dealerName',
                headerText: $localize`Dealer`,
                width: 150,
                filter: true
            },
            {
                field: 'posCode',
                headerText: $localize`Code(POS)`,
                width: 100,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'posName',
                headerText: $localize`POS`,
                width: 200,
                filter: true
            },
            {
                field: 'topupCount',
                headerText: $localize`Top-Up`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 100,
                type: 'number'
            },
            {
                field: 'cancelCount',
                headerText: $localize`Cancel`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 100,
                type: 'number'
            },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalProfit',
                headerText: $localize`Profit`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
    230: {
        headersForTotal: ['countTransactions', 'balance'],
        columns: [
            {
                field: 'dealerName',
                headerText: $localize`Dealer`,
                width: 150,
                filter: true
            },
            {
                field: 'posCode',
                headerText: $localize`Pos Code`,
                width: 80,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'posName',
                headerText: $localize`Name`,
                width: 150,
                filter: true
            },
            {
                field: 'countTransactions',
                headerText: $localize`Top-Ups`,
                width: 80,
                textAlign: 'Center',
                headerTextAlign: 'Center'
            },
            {
                field: 'balance',
                headerText: $localize`Balance`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
        ]
    },
    240: {
        headersForTotal: ['countTransactions', 'balance'],
        columns: [
            {
                field: 'providerName',
                headerText: $localize`Provider`,
                width: 120,
                filter: true
            },
            {
                field: 'productCode',
                headerText: $localize`Code(Product)`,
                width: 60,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'productName',
                headerText: $localize`Product`,
                width: 120,
                filter: true
            },
            {
                field: 'topupCount',
                headerText: $localize`Top-Up Count`,
                width: 100
            },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 120,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalProfit',
                headerText: $localize`Profit`,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                width: 150,
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'cancelsCount',
                headerText: $localize`Cancel Count`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center'
            },


        ]
    },
    250: {
        headersForTotal: ['topupCount', 'totalRetail', 'totalWholesale', 'grossProfit'],
        columns: [
            {
                field: 'posCode',
                headerText: $localize`Code`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            {
                field: 'posName',
                headerText: $localize`Name`,
                width: 150,
                filter: true
            },
            {
                field: 'dealerName',
                headerText: $localize`Dealer`,
                width: 150,
                filter: true
            },
            {
                field: 'topupCount',
                headerText: $localize`Top-Up Count`,
                width: 100
            },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            { field: 'grossProfit', headerText: $localize`Gross profit`, width: 150, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'grossProfitPercent', headerText: '%', width: 150, textAlign: 'Right', headerTextAlign: 'Center' }
        ]
    },
    251: {
        headersForTotal: ['topupCount', 'totalRetail', 'totalWholesale', 'grossProfit'],
        columns: [
            {
                field: 'clientCode',
                headerText: $localize`Code`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                filter: true,
                type: 'number'
            },
            { field: 'clientName', headerText: $localize`Name`, width: 150, filter: true },
            { field: 'dealerName', headerText: $localize`Dealer`, width: 150, filter: true },
            { field: 'providerName', headerText: $localize`Provider`, width: 150, filter: true },
            { field: 'topupCount', headerText: $localize`Top-Up Count`, width: 150 },
            {
                field: 'totalRetail',
                headerText: $localize`Retail`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            {
                field: 'totalWholesale',
                headerText: $localize`Wholesale`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            { field: 'grossProfit', headerText: $localize`Gross profit`, width: 150, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'grossProfitPercent', headerText: '%', width: 150, textAlign: 'Right', headerTextAlign: 'Center' }
        ]
    },
    260: {
        headersForTotal: ['creditLimit', 'balance'],
        columns: [
            { field: 'code', headerText: $localize`Code`, width: 150, textAlign: 'Right', headerTextAlign: 'Center', filter: true, type: 'number' },
            { field: 'name', headerText: $localize`Name`, width: 150, filter: true },
            { field: 'dealerName', headerText: $localize`Dealer`, width: 220, filter: true },
            { field: 'description', headerText: $localize`Description`, width: 220 },
            { field: 'contactPersonName', headerText: $localize`Contact Person`, width: 150, filter: true },
            { field: 'address', headerText: $localize`Address`, width: 150, filter: true },
            { field: 'city', headerText: $localize`City`, width: 150, filter: true },
            { field: 'documentId', headerText: $localize`BN`, width: 150, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'phone', headerText: $localize`Phone`, width: 150, filter: true, textAlign: 'Right', headerTextAlign: 'Center' },
            { field: 'email', headerText: $localize`email`, width: 150, filter: true },
            {
                field: 'creditLimit',
                headerText: $localize`Credit Limit`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            },
            { field: 'topupEnabled', headerText: $localize`Top-Up`, width: 60 },
            { field: 'recurringTopUpEnabled', headerText: $localize`Recurring`, width: 100 },
            { field: 'bulkTopUpEnabled', headerText: $localize`Bulk`, width: 60 },
            {
                field: 'lastTopup',
                headerText: $localize`Last Top-Up`,
                width: 150,
                filter: true,
                type: 'date',
                format: 'dd/MM/yyyy HH:mm:ss'
            },
            {
                field: 'balance',
                headerText: $localize`Balance`,
                width: 150,
                textAlign: 'Right',
                headerTextAlign: 'Center',
                HtmlTemplateName: HtmlTemplateEnum.Amount
            }
        ]
    },
};
